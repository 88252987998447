import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Paramount Estates
			</title>
			<meta name={"description"} content={"Відкрийте для себе місце, яке ви назвете домом"} />
			<meta property={"og:title"} content={"Paramount Estates"} />
			<meta property={"og:description"} content={"Відкрийте для себе місце, яке ви назвете домом"} />
			<meta property={"og:image"} content={"https://arctonixsell.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://arctonixsell.com/img/6466149.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://arctonixsell.com/img/6466149.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://arctonixsell.com/img/6466149.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://arctonixsell.com/img/6466149.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://arctonixsell.com/img/6466149.png"} />
			<meta name={"msapplication-TileImage"} content={"https://arctonixsell.com/img/6466149.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 220px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 100px 0px 100px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--primary"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Зв'яжіться з нами
				</Text>
				<Link
					href="mailto:blank?info@arctonixsell.com"
					text-align="center"
					color="--dark"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					info@arctonixsell.com
				</Link>
				<Link
					href="tel:0668683584"
					text-align="center"
					color="--dark"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					0668683584
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--dark" text-align="center">
					вул Зубрицьких, 28, Солонка, Львівська область, 81131
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});